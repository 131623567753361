<template>
    <v-dialog v-model="showDialog" persistent max-width="600">
      <v-card :loading="archiveDialogLoading">
        <v-toolbar color="red darken-4" dark flat>
          <v-toolbar-title>Archive this New Business Alert?</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-5">
          <v-textarea rows="2" max-length="350" counter="350"
                      v-model="finalnote"
                      no-resize
                      hint="Closing notes, to be included in the email and slack broadcast."
                      persistent-hint
                      clearable
                      outlined>
          </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn outlined ripple color="error" @click="showDialog = !showDialog">Cancel</v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn :disabled="reasons.length==0" outlined ripple v-on="on" class="ml-2" color="warning">Status</v-btn>
            </template>
            <span>{{reasons}}</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-btn ripple color="success" :disabled="allowToClose" @click="$emit('archiveAction', finalnote)">Archive</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios'
export default {
  name: 'archive-component',
  props: ['pid'],
  mounted: function () {
    this.archiveDialogLoading = true
    axios.get(`${this.$store.getters.NODEURL}/api/1/alert/closable/${this.pid}`, {
        withCredentials: true
      })
      .then((result) => {
        this.reasons = result.data.reasons[0]
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        this.archiveDialogLoading = false
      })
  },
  methods: {

  },
  data() {
    return {
      finalnote: '',
      reasons: '',
      showDialog: true,
      allowToClose: false,
      archiveDialogLoading: false
    }
  }
}
</script>